//config
import { config } from '../js/config'
//utils
import { logThis } from '../js/utils'
//custom code

//style
import '../style/style.css';

window.addEventListener('load', (event) => {
  if (config.debug) logThis('page is fully loaded');
});
