import { format } from 'date-fns';

/******
* debug utility 
 */
const logThis = (msg) => {
  let prefix = format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSS");
  console.log(prefix + " - " + msg);
}
export { logThis };

/******
* check if a class exist on an element 
 */
const hasClass = (element, className) => {
  return (' ' + element.className + ' ').indexOf(' ' + className + ' ') > -1;
}
export { hasClass };

/******
* add/remove multiple classe if a class exist on an element 
 */
const addRemoveClasses = (element, classNameArray, action) => {
  classNameArray.forEach((className) => {
    if (action == "add") element.classList.add(className);
    if (action == "remove") element.classList.remove(className);
  })
}
export { addRemoveClasses };